:root {
  //variables from Figma
  --primary-300: #3947d8;
  --primary-300-rgb: 57, 71, 216;
  --primary-100: #f5f5fb;
  --secondary-500: #068a99;
  --secondary-400: #0a9bab;
  --secondary-425: #1f9fae;
  --secondary-75: #e7fcfe;
  --secondary-375: #3db4c2;
  --secondary-300: #28b4c3;
  --secondary-100: #dff4f6;
  --tertiary-300: #38396f;
  --tertiary-200: #5756b3;
  --tertiary-275: #7577f1;
  --tertiary-450: #5051b6;
  --additional-grey-300: #7f89a7;
  --additional-page-bg: #f5f6fa;
  --additional-page-bg-rgb: 245, 246, 250;
  --white: #ffffff;
  --white-rgb: 255, 255, 255;
  --support-success-300: #0aab7b;
  --support-success-200: #a7e9cb;
  --support-success-100: #e5f9f0;
  --support-danger-500: #e93737;
  --support-danger-300: #ef8586;
  --support-danger-300-rgb: 239, 133, 134;
  --support-danger-200: #fa9e9f;
  --support-danger-100: #fee7e7;
  --support-warning-500: #f8bd29;
  --support-warning-300: #fbd678;
  --support-warning-200: #ffe5a3;
  --support-warning-100: #fdf1d2;
  --support-info-300: #006ce4;
  --support-info-200: #87bbf4;
  --support-info-100: #e5effa;
  --support-required: #de063a;
  --text-colors-title-300: #141418;
  --text-colors-title-primary: #3947d8;
  --text-colors-title-secondary: #28b4c3;
  --text-colors-title-tertiary-300: #38396f;
  --text-colors-title-tertiary-200: #5756b3;
  --text-colors-body-300: #141418;
  --text-colors-body-200: #7f89a7;
  --text-colors-body-100: #acb0bd;
  --text-colors-input-label: #141418;
  --text-colors-input-text: #141418;
  --text-colors-input-placeholder: #7f89a7;
  --text-colors-white: #ffffff;
  --separator-on-grey: #e1e9ef;
  --separator-on-white: #e5e7ed;
  --icon-primary: #3947d8;
  --icon-secondary: #28b4c3;
  --icon-400: #141418;
  --icon-300: #7f89a7;
  --icon-200: #cddae4;
  --icon-100: #ffffff;
  --rating-12-10: #28b4c3;
  --rating-9-7: #f7dc98;
  --rating-6-4: #6c63ff;
  --rating-3-1: #f3b5b4;
  --events-lt-purple: #c4c1ff;
  --events-lt-blue: #a4edff;
  --events-blue: #b1d2f9;
  --events-violete: #8891e8;
  --events-skinny: #fcf1d6;
  --events-azure: #ee5ff1;
  --events-yellow: #fffea9;
  --events-lt-peachy: #fcd3ae;
  --events-purple: #c09cee;
  --events-dark-violet: #b1bdff;
  --events-cian: #a6ecce;
  --events-lt-red: #fca3a3;
  --statistics-01: #fbdc8e;
  --statistics-02: #f3b5b4;
  --statistics-03: #28b4c3;
  --statistics-04: #5756b3;
  --statistics-05: #9697b8;
  --statistics-06: linear-gradient(180deg, #28b4c4 0%, #c7eff9 100%);
  --statistics-07: #fa9e9f;
  --statistics-08: #a7e9cb;
  --statistics-09: #f1e8d3;
  --statistics-10: #c6e4fb;
  --statistics-class-01: #a2e6d9;
  --statistics-class-02: #7da5df;
  --statistics-class-03: #af8beb;
  --statistics-class-04: #fec2c2;
  --statistics-class-05: #f28aee;
  --statistics-class-06: #feedc2;
  --statistics-class-07: #a89d9d;
  --statistics-class-08: #aaebf5;
  --statistics-class-09: #bad292;
  --statistics-class-10: #c6e4fb;
  --statistics-class-11: #8e6262;
  --files-image: #f7b84e;
  --files-audio: #50bee8;
  --files-video: #a066aa;
  --files-document: #84bd5a;
  --files-generic: #f15642;
  --font-size-body: 14px;
  --font-size-title: 36px;
  --font-size-h1: 28px;
  --font-size-h2: 24px;
  --font-size-h3: 20px;
  --font-size-h4: 18px;
  --font-size-h5: 16px;
  --font-size-h6: 12px;
  @media all and (max-width: 768px) {
    --font-size-title: 24px;
    --font-size-h1: 22px;
    --font-size-h2: 20px;
    --font-size-h3: 18px;
    --font-size-h4: 16px;
    --font-size-h5: 14px;
    --font-size-h6: 12px;
  }

  --black: #000000;
  --cards-header: #f3f9fb;
  --grey: #c4c4c4;
  --border-checkbox: #dae3eb;
  --scroll-bg: #cad4dd;
  --scroll-bg-hover: #b3bfc9;
  --input-bg: #f7fbff;
  --info-bg: #e0f2ff;
  --item-sel: #63baff;
  --item-sel-hov: #5daff0;
  --alice-blue: #eff8ff;
  --list-hover: #ebf1f7;
  --table-row-bg: #eaf5f999;

  //Not defined in figma
  --link-dark: #454660;
  --avatar-placeholder: #a4daef;
  --border-tip: rgba(40, 180, 195, 0.5);
  --border: rgba(0, 0, 0, 0.12);

  --public-id-bg: #f2f3fc;
  --additional-key-0: #fff;
  --additional-key-50: #fafafb;
  --additional-key-75: #f7f7f9;
  --additional-key-175: #e7e9ee;
  --additional-key-525: #7e88a6;
  --additional-key-550: #747fa0;
  --additional-key-925: #03091b;

  --isp-table-item-text: #fbfbfd;
  --isp-table-item-1: #699bf7;
  --isp-table-item-2: #792ed9;
}
