@mixin auto-grid($max-element-width, $gap, $mode: auto-fill) {
  display: grid;
  width: 100%;
  grid-template-columns: repeat($mode, minmax($max-element-width, 1fr));
  gap: $gap;
  & > * {
    justify-self: center;
    width: 100%;
    margin: 0;
  }
}

@mixin add-button-icon-container() {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--secondary-75);
  display: grid;
  place-items: center;
  color: var(--secondary-375);

  mat-icon {
    height: 100%;
    width: 100%;
    line-height: 1rem;
  }
}

@mixin class-list-item() {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  height: 150px;
  border-radius: 15px;
  text-align: center;
  line-height: 1.1;
  padding: 0.5rem;
  transition: transform 100ms;

  &:hover {
    transform: scale(1.02);
  }

  &__level,
  &__title {
    color: var(--secondary-500);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
