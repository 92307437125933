// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

$nit-blue-teal-primary: (
  50: #e9e9fb,
  100: #c6c8f4,
  200: #a0a5ed,
  300: #7881e6,
  400: #5964e0,
  500: #3946d8,
  600: #343ecd,
  700: #2a33c1,
  800: #2027b5,
  900: #070aa3,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$nit-blue-teal-accent: (
  50: #e1f8fc,
  100: #b4eef6,
  200: #83e2f1,
  300: #51d6ea,
  400: #2ccde5,
  500: #12c4e0,
  600: #0fb4cc,
  700: #0a9eb1,
  800: #068a99,
  900: #00676d,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$nit-red-warn: (
  50: #ffeef1,
  100: #ffd5da,
  200: #f6a7a9,
  300: #ef8587,
  400: #fc6a68,
  500: #ff5b4f,
  600: #f6544f,
  700: #e34b48,
  800: #d64440,
  900: #c83a34,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$uis-front-primary: mat.m2-define-palette($nit-blue-teal-primary);
$uis-front-accent: mat.m2-define-palette($nit-blue-teal-accent);

// The warn palette is optional (defaults to red).
$uis-front-warn: mat.m2-define-palette($nit-red-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$uis-front-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $uis-front-primary,
      accent: $uis-front-accent,
      warn: $uis-front-warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: 'GT Walsheim Pro',
      ),
    density: -2,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($uis-front-theme);

body {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 10px;
  }

  .mat-mdc-form-field {
    width: 100%;
    background-color: var(--white);
    box-shadow: 0 0 24px rgba(156, 182, 201, 0.15);
  }

  .mat-mdc-checkbox label {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  // TODO: Selector can change after any material update, should be checked from time to time!
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
    border-color: transparent;
  }

  .mdc-notched-outline__leading {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
  .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden !important;
  }
}

mat-icon {
  color: var(--text-colors-body-300);
}

.uis-mat-snack-bar {
  background-color: var(--alice-blue);
  border-radius: 7px;

  .mdc-snackbar__label {
    padding: 0;
  }

  .mdc-snackbar__surface {
    background-color: transparent !important;
    padding: 0;
    min-width: 100px !important;
    box-shadow: none !important;
  }
}

.mat-mdc-dialog-content {
  scrollbar-gutter: stable both-edges;
}
