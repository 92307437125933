@import 'src/assets/styles/fonts';
@import 'src/assets/styles/font-preload';
@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/typography';
@import 'src/assets/styles/material';
@import 'src/assets/styles/reusable-classes';
@import 'src/assets/styles/reset';
@import 'src/assets/styles/animations';
@import 'src/assets/styles/splash-screen';
@import 'src/assets/styles/mixins';
@import 'src/assets/styles/forms-common';

.uis-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.routes-animations-container {
  position: relative;

  & > *:not(router-outlet) {
    flex-grow: 1;
  }
}
