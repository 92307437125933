body,
input,
button,
textarea {
  font-family: 'GT Walsheim Pro', sans-serif;
}

h2,
h3 {
  color: var(--text-colors-title-300);
}

h2 {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
