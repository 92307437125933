* {
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;

  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background: var(--additional-page-bg);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: var(--scroll-bg);
    border-radius: 4px;
    outline: 1px solid transparent;
    background-clip: content-box;
    border: 2px solid var(--additional-page-bg);

    &:hover {
      background-color: var(--scroll-bg-hover);
      width: 10px;
      border: none;
    }
  }

  // CSS debug trick :D
  //outline: 1px solid rgba(red, 0.3);
}

html {
  scrollbar-gutter: stable;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  line-height: 1;
  min-height: 100vh;
  background-color: var(--additional-page-bg);

  a {
    color: inherit;
    text-decoration: none;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button {
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
}

// Remove number input arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}
