.splash-screen {
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
  background-color: var(--additional-page-bg);

  &__logo {
    margin-bottom: 4rem;
    animation: fade-pulse 2s infinite alternate;
  }

  &__lw {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    width: 5rem;
    display: grid;
    place-items: center;
    text-decoration: none;
    color: black;
    opacity: 0.5;
    transition: opacity 500ms;

    &:hover {
      opacity: 1;
    }

    &__text {
      font-size: 12px;
    }

    &__logo {
      width: 100%;
      object-position: center;
      object-fit: contain;
    }
  }
}

@keyframes fade-pulse {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
