.nm-card {
  box-shadow:
    -4px -4px 10px var(--white),
    4px 4px 8px #9cb6c933,
    inset -4px -4px 10px #fffc,
    inset 4px 4px 8px #9cb6c91a;
  background-color: #f5f6fa;
}

.decorations-parent {
  position: relative;
  z-index: 1;
}

.decorations {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  inset: 0;
  z-index: -1;

  & > * {
    position: absolute;
    object-fit: contain;
    object-position: center;
  }
}

.uis-centered-page {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 885px;
}

.pointer-transparent {
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}

.uis-activated-link {
  color: blue !important;
}
