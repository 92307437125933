.load-font {
  visibility: hidden;

  &_whp {
    font-family: 'GT Walsheim Pro', sans-serif;
  }

  &_400 {
    font-weight: 400;
  }

  &_500 {
    font-weight: 500;
  }

  &_600 {
    font-weight: 600;
  }
}
